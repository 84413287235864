import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import ConfirmationPage from "./pages/confirmation";
import Moviespage from "./pages/movies";
import Paymentpage from "./pages/payment";
import FNB from "./pages/FNB";
import SeatLayoutpage from "./pages/seatlayout";
import Showtimepage from "./pages/showtime";
import Splashpage from "./pages/splash";
import SwipecardPage from "./pages/swipecard";
import LoginPage from "./pages/login";
import YourdetailsPage from "./pages/yourdetails";
import RecentBookings from "./pages/recentBookings";
import OrderReview from "./pages/reviewOrder";
import IdleTimerContainer from "./utils/IdleTimerHandling";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idleTimerSet: process.env.REACT_APP_IDLETIMERCOUNTER,
      incrementTime: 60000
    };
  }

  timerIncrement = () => {
    var getIdleTimer = parseInt(this.state.idleTimerSet);
    var getIncrementTime = parseInt(this.state.incrementTime);
    var totalTimer = getIdleTimer + getIncrementTime;
    console.log(totalTimer, 'totalTimer--increment');
    this.setState({
      idleTimerSet: totalTimer
    })
  }

  timerDecrement = () => {
    this.setState({
      idleTimerSet: process.env.REACT_APP_IDLETIMERCOUNTER
    }, () => {
      console.log(this.state.idleTimerSet, 'totalTimer--Decrement');
    })
  }

  timerInConfirmationPageDecrement = () => {
    this.setState({
      idleTimerSet: 30000
    }, () => {
      console.log(this.state.idleTimerSet, 'confirmation page---Idle Decrement');
    })
  }
  componentDidMount() {
    // Disable pinch zoom for gesture events (Safari and others)
    document.addEventListener("gesturestart", this.preventZoom, { passive: false });
    document.addEventListener("gesturechange", this.preventZoom, { passive: false });

    // Disable pinch zoom for touchstart (Android, iOS, and others)
    document.addEventListener("touchmove", this.preventMultiTouchZoom, { passive: false });

    // Disable pointer zoom for Windows touchscreens
    document.addEventListener("pointerdown", this.preventPointerZoom, { passive: false });
  }

  componentWillUnmount() {
    document.removeEventListener("gesturestart", this.preventZoom);
    document.removeEventListener("gesturechange", this.preventZoom);
    document.removeEventListener("touchmove", this.preventMultiTouchZoom);
    document.removeEventListener("pointerdown", this.preventPointerZoom);
  }

  // Prevent zoom from gesture events
  preventZoom = (e) => {
    console.log('preventZoom');
    e.preventDefault();
  };

  // Prevent pinch-to-zoom (multi-touch gesture)
  preventMultiTouchZoom = (e) => {
    if (e.touches && e.touches.length > 1) {
      console.log('preventMultiTouchZoom');
      e.preventDefault();
    }
  };

  // Prevent zoom from pointer events
  preventPointerZoom = (e) => {
    if (e.pointerType === "touch" && e.isPrimary) {
      console.log('preventPointerZoom');
      e.preventDefault();
    }
  };

  render() {
    const idleTimerHidePages = window.location.pathname == "/" || window.location.pathname == "/swipecard";
    return (
      <>
        {/* <Header></Header> */}
        {
          idleTimerHidePages ? <></> : <IdleTimerContainer idleTimerSet={this.state.idleTimerSet} />
        }
        <Routes>
          <Route path="/">
            <Route index element={<Splashpage />} />
            <Route path="movies" element={<Moviespage />} />
            <Route path="showtime" element={<Showtimepage />} />
            <Route path="login" element={<LoginPage timerIncrement={this.timerIncrement} timerDecrement={this.timerDecrement} />} />
            <Route path="yourdetails" element={<YourdetailsPage />} />
            {/* <Route path="success" element={<Successpage />} /> */}
            {/* <Route path="failure" element={<Failurepage />} /> */}
            <Route path="seatlayout" element={<SeatLayoutpage />} />
            <Route path="fnb" element={<FNB />} />
            <Route path="payment" element={<Paymentpage timerIncrement={this.timerIncrement} timerDecrement={this.timerDecrement} />} />
            <Route path="confirmation" element={<ConfirmationPage timerInConfirmationPageDecrement={this.timerInConfirmationPageDecrement} />} />
            <Route path="swipecard" element={<SwipecardPage />} />
            <Route path="recent-bookings" element={<RecentBookings />} />
            <Route path="order-review" element={<OrderReview />} />
          </Route>
        </Routes>
      </>
    );
  }
}

export default App;
